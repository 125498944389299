@layer modules, ui, base;
@layer ui {
  .sk-MultiSelect_main--read-only__Sqsuj .sk-MultiSelect_trigger__5sHRW {
  cursor: not-allowed;
}

.sk-MultiSelect_dropdownContainer__ed5wo {
  position: relative;
}

.sk-MultiSelect_trigger__5sHRW {
  display: flex;
  position: relative;
  box-sizing: border-box;
  justify-content: flex-end;
  width: 100%;
  height: var(--sk-size-48);
  padding: var(--sk-space-16);
  border-width: var(--sk-form-multiselect-field-size-border-width);
  border-style: solid;
  border-radius: var(--sk-form-multiselect-field-radius);
  outline: unset;
  cursor: pointer;
  place-items: center;
  gap: var(--sk-space-8);
}

.sk-MultiSelect_trigger__5sHRW .Placeholder {
  flex-grow: 1;
  padding-right: var(--sk-space-4);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.sk-MultiSelect_trigger__5sHRW .SelectedOptionLabel,
.sk-MultiSelect_trigger__5sHRW .Placeholder {
  overflow: hidden;
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sk-MultiSelect_icon__qCO9v {
  flex-shrink: 0;
}

.sk-MultiSelect_selectIcon__6_0Xu {
  flex-grow: 0;
  flex-shrink: 0;
}

/* ON LIGHT BACKGROUND */

.sk-MultiSelect_main--light__9u4y3 {
  color: var(--sk-form-multiselect-color-light);
}

.sk-MultiSelect_main--light__9u4y3 .SelectedOptionLabel {
  color: var(--sk-form-field-color-default-light);
}

.sk-MultiSelect_main--light__9u4y3 .sk-MultiSelect_subLabel__O6hSZ {
  color: var(--sk-form-multiselect-secondary-label-color-light);
}

.sk-MultiSelect_main--light__9u4y3 .sk-MultiSelect_SelectedOptionSecondaryLabel__mmsON {
  color: var(--sk-color-grey-600);
}

.sk-MultiSelect_main--light__9u4y3 .sk-MultiSelect_message__m3XE0 {
  color: var(--sk-form-multiselect-message-color-default-light);
}

.sk-MultiSelect_main--light__9u4y3 .sk-MultiSelect_message--error__i3PZ9 {
  color: var(--sk-form-multiselect-message-color-error-light);
}

.sk-MultiSelect_main--light__9u4y3 .sk-MultiSelect_trigger__5sHRW {
  border-color: var(--sk-form-multiselect-field-color-border-default-light);
  background-color: var(--sk-form-multiselect-field-color-background-default-light);
  color: var(--sk-form-multiselect-field-color-default-light);
}

.sk-MultiSelect_main--light__9u4y3 .Placeholder {
  color: var(--sk-form-multiselect-field-placeholder-color-light);
}

.sk-MultiSelect_main--light__9u4y3.sk-MultiSelect_main--read-only__Sqsuj .sk-MultiSelect_trigger__5sHRW {
  border-color: var(--sk-form-multiselect-field-color-border-read-only-light);
  background-color: var(--sk-form-multiselect-field-color-background-read-only-light);
  color: var(--sk-form-multiselect-field-color-read-only-light);
}

.sk-MultiSelect_main--light__9u4y3 .sk-MultiSelect_icon__qCO9v,
.sk-MultiSelect_main--light__9u4y3 .sk-MultiSelect_selectIcon__6_0Xu {
  color: var(--sk-form-multiselect-field-expand-icon-color-default-light);
}

.sk-MultiSelect_main--light__9u4y3.sk-MultiSelect_main--read-only__Sqsuj .sk-MultiSelect_icon__qCO9v,
.sk-MultiSelect_main--light__9u4y3.sk-MultiSelect_main--read-only__Sqsuj .sk-MultiSelect_selectIcon__6_0Xu {
  color: var(--sk-form-multiselect-field-expand-icon-color-read-only-light);
}

.sk-MultiSelect_main--light__9u4y3.sk-MultiSelect_main--error__Trncc .sk-MultiSelect_trigger__5sHRW {
  border-color: var(--sk-form-multiselect-field-color-border-error-default-light);
}

.sk-MultiSelect_main--light__9u4y3.sk-MultiSelect_main--error__Trncc .sk-MultiSelect_trigger__5sHRW:focus-visible {
  border-color: var(--sk-form-multiselect-field-color-border-error-focus-light);
}

.sk-MultiSelect_main--light__9u4y3.sk-MultiSelect_main--error__Trncc .sk-MultiSelect_trigger__5sHRW:hover:not(:focus-visible) {
  border-color: var(--sk-form-multiselect-field-color-border-error-hover-light);
}

.sk-MultiSelect_main--light__9u4y3.sk-MultiSelect_main--error__Trncc .sk-MultiSelect_trigger__5sHRW:active {
  border-color: var(--sk-form-multiselect-field-color-border-error-active-light);
}

.sk-MultiSelect_main--light__9u4y3.sk-MultiSelect_main--read-only__Sqsuj .sk-MultiSelect_trigger__5sHRW:focus-visible {
  border-color: var(--sk-form-multiselect-field-color-border-read-only-focus-light);
  background-color: var(--sk-form-multiselect-field-color-background-read-only-focus-light);
}

.sk-MultiSelect_main--light__9u4y3:not(.sk-MultiSelect_main--read-only__Sqsuj, .sk-MultiSelect_main--error__Trncc) .sk-MultiSelect_trigger__5sHRW:hover {
  border-color: var(--sk-form-multiselect-field-color-border-hover-light);
  background-color: var(--sk-form-multiselect-field-color-background-hover-light);
}

.sk-MultiSelect_main--light__9u4y3:not(.sk-MultiSelect_main--read-only__Sqsuj, .sk-MultiSelect_main--error__Trncc) .sk-MultiSelect_trigger__5sHRW:active {
  border-color: var(--sk-form-multiselect-field-color-border-active-light);
  background-color: var(--sk-form-multiselect-field-color-background-active-light);
}

.sk-MultiSelect_main--light__9u4y3:not(.sk-MultiSelect_main--read-only__Sqsuj, .sk-MultiSelect_main--error__Trncc) .sk-MultiSelect_trigger__5sHRW:focus-visible {
  border-color: var(--sk-form-multiselect-field-color-border-focus-light);
  background-color: var(--sk-form-multiselect-field-color-background-focus-light);
}

.sk-MultiSelect_main--light__9u4y3 .sk-MultiSelect_clearIcon__1yaR8 {
  color: var(--sk-form-multiselect-field-clear-icon-color-light);
}

/* ON DARK BACKGROUND */

.sk-MultiSelect_main--dark__9zHVJ {
  color: var(--sk-form-multiselect-color-dark);
}

.sk-MultiSelect_main--dark__9zHVJ .SelectedOptionLabel {
  color: var(--sk-form-field-color-default-dark);
}

.sk-MultiSelect_main--dark__9zHVJ .sk-MultiSelect_SelectedOptionSecondaryLabel__mmsON {
  color: var(--sk-color-grey-300);
}

.sk-MultiSelect_main--dark__9zHVJ .sk-MultiSelect_subLabel__O6hSZ {
  color: var(--sk-form-multiselect-secondary-label-color-dark);
}

.sk-MultiSelect_main--dark__9zHVJ .sk-MultiSelect_message__m3XE0 {
  color: var(--sk-form-multiselect-message-color-default-dark);
}

.sk-MultiSelect_main--dark__9zHVJ .sk-MultiSelect_message--error__i3PZ9 {
  color: var(--sk-form-multiselect-message-color-error-dark);
}

.sk-MultiSelect_main--dark__9zHVJ .sk-MultiSelect_trigger__5sHRW {
  border-color: var(--sk-form-multiselect-field-color-border-default-dark);
  background-color: var(--sk-form-multiselect-field-color-background-default-dark);
  color: var(--sk-form-multiselect-field-color-default-dark);
}

.sk-MultiSelect_main--dark__9zHVJ .Placeholder {
  color: var(--sk-form-multiselect-field-placeholder-color-dark);
}

.sk-MultiSelect_main--dark__9zHVJ.sk-MultiSelect_main--read-only__Sqsuj .sk-MultiSelect_trigger__5sHRW {
  border-color: var(--sk-form-multiselect-field-color-border-read-only-dark);
  background-color: var(--sk-form-multiselect-field-color-background-read-only-dark);
  color: var(--sk-form-multiselect-field-color-read-only-dark);
}

.sk-MultiSelect_main--dark__9zHVJ .sk-MultiSelect_icon__qCO9v,
.sk-MultiSelect_main--dark__9zHVJ .sk-MultiSelect_selectIcon__6_0Xu {
  color: var(--sk-form-multiselect-field-expand-icon-color-default-dark);
}

.sk-MultiSelect_main--dark__9zHVJ.sk-MultiSelect_main--read-only__Sqsuj .sk-MultiSelect_icon__qCO9v,
.sk-MultiSelect_main--dark__9zHVJ.sk-MultiSelect_main--read-only__Sqsuj .sk-MultiSelect_selectIcon__6_0Xu {
  color: var(--sk-form-multiselect-field-expand-icon-color-read-only-dark);
}

.sk-MultiSelect_main--dark__9zHVJ.sk-MultiSelect_main--error__Trncc .sk-MultiSelect_trigger__5sHRW {
  border-color: var(--sk-form-multiselect-field-color-border-error-default-dark);
}

.sk-MultiSelect_main--dark__9zHVJ.sk-MultiSelect_main--error__Trncc .sk-MultiSelect_trigger__5sHRW:focus-visible {
  border-color: var(--sk-form-multiselect-field-color-border-error-focus-dark);
}

.sk-MultiSelect_main--dark__9zHVJ.sk-MultiSelect_main--error__Trncc .sk-MultiSelect_trigger__5sHRW:hover:not(:focus-visible) {
  border-color: var(--sk-form-multiselect-field-color-border-error-hover-dark);
}

.sk-MultiSelect_main--dark__9zHVJ.sk-MultiSelect_main--error__Trncc .sk-MultiSelect_trigger__5sHRW:active {
  border-color: var(--sk-form-multiselect-field-color-border-error-active-dark);
}

.sk-MultiSelect_main--dark__9zHVJ.sk-MultiSelect_main--read-only__Sqsuj .sk-MultiSelect_trigger__5sHRW:focus-visible {
  border-color: var(--sk-form-multiselect-field-color-border-read-only-focus-dark);
  background-color: var(--sk-form-multiselect-field-color-background-read-only-focus-dark);
}

.sk-MultiSelect_main--dark__9zHVJ:not(.sk-MultiSelect_main--read-only__Sqsuj, .sk-MultiSelect_main--error__Trncc) .sk-MultiSelect_trigger__5sHRW:hover {
  border-color: var(--sk-form-multiselect-field-color-border-hover-dark);
  background-color: var(--sk-form-multiselect-field-color-background-hover-dark);
}

.sk-MultiSelect_main--dark__9zHVJ:not(.sk-MultiSelect_main--read-only__Sqsuj, .sk-MultiSelect_main--error__Trncc) .sk-MultiSelect_trigger__5sHRW:active {
  border-color: var(--sk-form-multiselect-field-color-border-active-dark);
  background-color: var(--sk-form-multiselect-field-color-background-active-dark);
}

.sk-MultiSelect_main--dark__9zHVJ:not(.sk-MultiSelect_main--read-only__Sqsuj, .sk-MultiSelect_main--error__Trncc) .sk-MultiSelect_trigger__5sHRW:focus-visible {
  border-color: var(--sk-form-multiselect-field-color-border-focus-dark);
  background-color: var(--sk-form-multiselect-field-color-background-focus-dark);
}

.sk-MultiSelect_main--dark__9zHVJ .sk-MultiSelect_clearIcon__1yaR8 {
  color: var(--sk-form-multiselect-field-clear-icon-color-dark);
}

}
@layer ui {
  .sk-Select_main--disabled__a6jig .sk-Select_trigger__K7tRY {
  cursor: not-allowed;
}

.sk-Select_dropdownContainer__UCrwh {
  position: relative;
}

.sk-Select_trigger__K7tRY {
  display: flex;
  position: relative;
  box-sizing: border-box;
  justify-content: flex-end;
  width: 100%;
  height: var(--sk-size-48);
  padding: var(--sk-space-16);
  border-width: var(--sk-form-select-field-size-border-width);
  border-style: solid;
  border-radius: var(--sk-form-select-field-radius);
  outline: unset;
  cursor: pointer;
  place-items: center;
  gap: var(--sk-space-8);
}

.sk-Select_trigger__K7tRY .Placeholder {
  flex-grow: 1;
  padding-right: var(--sk-space-4);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.sk-Select_trigger__K7tRY .SelectedOptionLabel,
.sk-Select_trigger__K7tRY .Placeholder {
  overflow: hidden;
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sk-Select_selectIcon___QndH {
  flex-grow: 0;
  flex-shrink: 0;
}

.sk-Select_icon__sX1k2 {
  flex-shrink: 0;
}

.sk-Select_optionImageContainer__qZF1o {
  width: var(--sk-size-20);
  height: var(--sk-size-20);
}

.sk-Select_optionImageContainer__qZF1o > * {
  border-radius: var(--sk-radius-4);
}

/* ON LIGHT BACKGROUND */

.sk-Select_main--light__ELftu {
  color: var(--sk-form-select-color-light);
}

.sk-Select_main--light__ELftu .SelectedOptionLabel {
  color: var(--sk-form-field-color-default-light);
}

.sk-Select_main--light__ELftu .sk-Select_subLabel__cvCLQ {
  color: var(--sk-form-select-secondary-label-color-light);
}

.sk-Select_main--light__ELftu .sk-Select_SelectedOptionSecondaryLabel__QYl6E {
  color: var(--sk-color-grey-600);
}

.sk-Select_main--light__ELftu .sk-Select_message__5VTU5 {
  color: var(--sk-form-select-message-color-default-light);
}

.sk-Select_main--light__ELftu .sk-Select_message--error__p6wwe {
  color: var(--sk-form-select-message-color-error-light);
}

.sk-Select_main--light__ELftu .sk-Select_trigger__K7tRY {
  border-color: var(--sk-form-select-field-color-border-default-light);
  background-color: var(--sk-form-select-field-color-background-default-light);
  color: var(--sk-form-select-field-color-default-light);
}

.sk-Select_main--light__ELftu .Placeholder {
  color: var(--sk-form-select-field-placeholder-color-light);
}

.sk-Select_main--light__ELftu.sk-Select_main--disabled__a6jig .sk-Select_trigger__K7tRY {
  border-color: var(--sk-form-select-field-color-border-disabled-light);
  background-color: var(--sk-form-select-field-color-background-disabled-light);
  color: var(--sk-form-select-field-color-disabled-light);
}

.sk-Select_main--light__ELftu .sk-Select_icon__sX1k2,
.sk-Select_main--light__ELftu .sk-Select_selectIcon___QndH {
  color: var(--sk-form-select-field-expand-icon-color-default-light);
}

.sk-Select_main--light__ELftu.sk-Select_main--disabled__a6jig .sk-Select_icon__sX1k2,
.sk-Select_main--light__ELftu.sk-Select_main--disabled__a6jig .sk-Select_selectIcon___QndH {
  color: var(--sk-form-select-field-expand-icon-color-disabled-light);
}

.sk-Select_main--light__ELftu.sk-Select_main--error__rL6Zr .sk-Select_trigger__K7tRY {
  border-color: var(--sk-form-select-field-color-border-error-default-light);
}

.sk-Select_main--light__ELftu.sk-Select_main--error__rL6Zr .sk-Select_trigger__K7tRY:focus-visible {
  border-color: var(--sk-form-select-field-color-border-error-focus-light);
}

.sk-Select_main--light__ELftu.sk-Select_main--error__rL6Zr .sk-Select_trigger__K7tRY:hover:not(:focus-visible) {
  border-color: var(--sk-form-select-field-color-border-error-hover-light);
}

.sk-Select_main--light__ELftu.sk-Select_main--error__rL6Zr .sk-Select_trigger__K7tRY:active {
  border-color: var(--sk-form-select-field-color-border-error-active-light);
}

.sk-Select_main--light__ELftu.sk-Select_main--disabled__a6jig .sk-Select_trigger__K7tRY:focus-visible {
  border-color: var(--sk-form-select-field-color-border-disabled-focus-light);
  background-color: var(--sk-form-select-field-color-background-disabled-focus-light);
}

.sk-Select_main--light__ELftu:not(.sk-Select_main--disabled__a6jig, .sk-Select_main--error__rL6Zr) .sk-Select_trigger__K7tRY:hover {
  border-color: var(--sk-form-select-field-color-border-hover-light);
  background-color: var(--sk-form-select-field-color-background-hover-light);
}

.sk-Select_main--light__ELftu:not(.sk-Select_main--disabled__a6jig, .sk-Select_main--error__rL6Zr) .sk-Select_trigger__K7tRY:active {
  border-color: var(--sk-form-select-field-color-border-active-light);
  background-color: var(--sk-form-select-field-color-background-active-light);
}

.sk-Select_main--light__ELftu:not(.sk-Select_main--disabled__a6jig, .sk-Select_main--error__rL6Zr) .sk-Select_trigger__K7tRY:focus-visible {
  border-color: var(--sk-form-select-field-color-border-focus-light);
  background-color: var(--sk-form-select-field-color-background-focus-light);
}

/* ON DARK BACKGROUND */

.sk-Select_main--dark__Grzof {
  color: var(--sk-form-select-color-dark);
}

.sk-Select_main--dark__Grzof .SelectedOptionLabel {
  color: var(--sk-form-field-color-default-dark);
}

.sk-Select_main--dark__Grzof .sk-Select_SelectedOptionSecondaryLabel__QYl6E {
  color: var(--sk-color-grey-300);
}

.sk-Select_main--dark__Grzof .sk-Select_subLabel__cvCLQ {
  color: var(--sk-form-select-secondary-label-color-dark);
}

.sk-Select_main--dark__Grzof .sk-Select_message__5VTU5 {
  color: var(--sk-form-select-message-color-default-dark);
}

.sk-Select_main--dark__Grzof .sk-Select_message--error__p6wwe {
  color: var(--sk-form-select-message-color-error-dark);
}

.sk-Select_main--dark__Grzof .sk-Select_trigger__K7tRY {
  border-color: var(--sk-form-select-field-color-border-default-dark);
  background-color: var(--sk-form-select-field-color-background-default-dark);
  color: var(--sk-form-select-field-color-default-dark);
}

.sk-Select_main--dark__Grzof .Placeholder {
  color: var(--sk-form-select-field-placeholder-color-dark);
}

.sk-Select_main--dark__Grzof.sk-Select_main--disabled__a6jig .sk-Select_trigger__K7tRY {
  border-color: var(--sk-form-select-field-color-border-disabled-dark);
  background-color: var(--sk-form-select-field-color-background-disabled-dark);
  color: var(--sk-form-select-field-color-disabled-dark);
}

.sk-Select_main--dark__Grzof .sk-Select_icon__sX1k2,
.sk-Select_main--dark__Grzof .sk-Select_selectIcon___QndH {
  color: var(--sk-form-select-field-expand-icon-color-default-dark);
}

.sk-Select_main--dark__Grzof.sk-Select_main--disabled__a6jig .sk-Select_icon__sX1k2,
.sk-Select_main--dark__Grzof.sk-Select_main--disabled__a6jig .sk-Select_selectIcon___QndH {
  color: var(--sk-form-select-field-expand-icon-color-disabled-dark);
}

.sk-Select_main--dark__Grzof.sk-Select_main--error__rL6Zr .sk-Select_trigger__K7tRY {
  border-color: var(--sk-form-select-field-color-border-error-default-dark);
}

.sk-Select_main--dark__Grzof.sk-Select_main--error__rL6Zr .sk-Select_trigger__K7tRY:focus-visible {
  border-color: var(--sk-form-select-field-color-border-error-focus-dark);
}

.sk-Select_main--dark__Grzof.sk-Select_main--error__rL6Zr .sk-Select_trigger__K7tRY:hover:not(:focus-visible) {
  border-color: var(--sk-form-select-field-color-border-error-hover-dark);
}

.sk-Select_main--dark__Grzof.sk-Select_main--error__rL6Zr .sk-Select_trigger__K7tRY:active {
  border-color: var(--sk-form-select-field-color-border-error-active-dark);
}

.sk-Select_main--dark__Grzof.sk-Select_main--disabled__a6jig .sk-Select_trigger__K7tRY:focus-visible {
  border-color: var(--sk-form-select-field-color-border-disabled-focus-dark);
  background-color: var(--sk-form-select-field-color-background-disabled-focus-dark);
}

.sk-Select_main--dark__Grzof:not(.sk-Select_main--disabled__a6jig, .sk-Select_main--error__rL6Zr) .sk-Select_trigger__K7tRY:hover {
  border-color: var(--sk-form-select-field-color-border-hover-dark);
  background-color: var(--sk-form-select-field-color-background-hover-dark);
}

.sk-Select_main--dark__Grzof:not(.sk-Select_main--disabled__a6jig, .sk-Select_main--error__rL6Zr) .sk-Select_trigger__K7tRY:active {
  border-color: var(--sk-form-select-field-color-border-active-dark);
  background-color: var(--sk-form-select-field-color-background-active-dark);
}

.sk-Select_main--dark__Grzof:not(.sk-Select_main--disabled__a6jig, .sk-Select_main--error__rL6Zr) .sk-Select_trigger__K7tRY:focus-visible {
  border-color: var(--sk-form-select-field-color-border-focus-dark);
  background-color: var(--sk-form-select-field-color-background-focus-dark);
}

}
